import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=12db3626&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=js&"
export * from "./Navbar.vue?vue&type=script&lang=js&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=12db3626&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12db3626",
  null
  
)


    import installComponents from "!/app/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BNavbarBrand, BNavbarToggle, BNavbarNav, BNavItem, BCollapse, BNavbar} from 'bootstrap-vue'
    installComponents(component, {BNavbarBrand, BNavbarToggle, BNavbarNav, BNavItem, BCollapse, BNavbar})
    

export default component.exports